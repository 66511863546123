import { useState, useEffect } from "react";
import React from "react";
import {
  Grid,
  makeStyles,
  Typography,
  Button,
  Box,
  Modal,
  withStyles,
} from "@material-ui/core";
import ReactPlayer from "react-player";
import MapAgroSuper from "../../Molecules/MapAgroSuper";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import myVideo from '../../../assets/videos/master.mp4';
import logo from "../../../assets/Logo-SKYVIEW_blanco.svg";
import recorrido from "../../../assets/recorrido_all.png";
import { findByLabelText } from "@testing-library/react";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  tittle: {
    color: theme.palette.text.button,
  },
  containerButtons: {
    position: "relative",
    zIndex: 2,
    transform: "translate(30px, 200px)",
  },
  containerlogo: {
    position: "absolute",
    zIndex: 3,
    transform: "translate(35px, 100px)",
  },
  containerrecorrido: {
    position: "absolute",
    zIndex: 10,
    transform: "translate(32vw, 85vh)",

  },
  button: {
    marginRight: "10px",
  },
  exitButton:{
    position: 'relative',
    float: "right",
    zIndex:1,
  },
  wrapper: {
    borderRadius: "20px",
  },
  block: {
    width: "100%",
    height: "70%",
    background: "white",
    position: "absolute",
    zIndex: -1,
  },
}));

const WelcomeSection = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
 

  const handleClose = () => {
    sessionStorage["PopupShown"] = "yes";
    setOpen(false);
  };

  const StyledButton = withStyles({
    root: {
      color: "#FFF",
      borderColor: "#FFF",
    },
    label: {
      textTransform: "capitalize",
    },
  })(Button  );

  return (  
    <>
      <div className={classes.block}></div>
      <div id="home" className={classes.root}>
        <Modal open={ sessionStorage["PopupShown"] === "yes" ? false : open } onClose={handleClose} className="modal">
          <div style={{ borderRadius: 10, outline: 0 , width:"80%"}}>
            <ReactPlayer
              // Disable download button
              config={{ file: { attributes: { controlsList: 'nodownload' } } }}

              // Disable right click
              onContextMenu={e => e.preventDefault()}            
              url={myVideo}
              volume={0.2}
              width="100%"
              height="100%"
              playing={true}
              loop={true}
              controls={true}
            />
            <StyledButton variant="outlined" onClick={handleClose} size="small" className={classes.exitButton}>
              {" "}
              Saltar video{" "}
            </StyledButton>
          </div>
        </Modal>

        <Grid container>
          <Box
              className={classes.containerlogo}
              component={"img"}
              mt={3}
              width={250}
              src={logo}
              alt=" logo"
            />
            <Box
              className={classes.containerrecorrido}
              component={"img"}
              mt={3}
              width={550}
              src={recorrido}
              alt="recorrido_all"
            />

          <TransformWrapper
            initialScale={1.2}
            minScale={1.2}
            maxScale={1.6}
            initialPositionX={-2000}
            initialPositionY={-100}
            wrapperStyle={{borderRadius:"50px"}}
          >
            {({ zoomIn, zoomOut, resetTransform }) => (
              <React.Fragment>
                <div className={classes.containerButtons}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => zoomIn()}
                    className={classes.button}
                  >
                    Zoom in{" "}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => zoomOut()}
                    className={classes.button}
                  >
                    Zoom out
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => resetTransform()}
                    className={classes.button}
                  >
                    Reset
                  </Button>
                </div>
                <TransformComponent>
                   
                  <MapAgroSuper />
                </TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>
        </Grid>
      </div>
    </>
  );
};

export default WelcomeSection;
