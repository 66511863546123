import proceso1 from "../assets/f1.png"
import proceso2 from "../assets/f2.png"
import proceso3 from "../assets/f3.png"
import proceso4 from "../assets/f4.png"
import proceso5 from "../assets/f5.png"
import proceso6 from "../assets/f6.png"
import proceso7 from "../assets/f7.png"
import proceso8 from "../assets/f8.png"
import proceso9 from "../assets/f9.png"
import proceso10 from "../assets/f10.png"
import proceso11 from "../assets/f11.png"
import proceso12 from "../assets/f12.png"

const processImage = [
    {
        name:"f1",
        url:proceso1
    },
    {
        name:"f2",
        url:proceso2
    },
    {
        name:"f3",
        url:proceso3
    },
    {
        name:"f4",
        url:proceso4
    },
    {
        name:"f5",
        url:proceso5
    },
    {
        name:"f6",
        url:proceso6
    },
    {
        name:"f7",
        url:proceso7
    },
    {
        name:"f8",
        url:proceso8
    },
    {
        name:"f9",
        url:proceso9
    },
    {
        name:"f10",
        url:proceso10
    },
    {
        name:"f11",
        url:proceso11
    },
    {
        name:"f12",
        url:proceso12
    }

]

export default processImage
