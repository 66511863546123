import React from "react";
import WelcomeSection from "../../Organism/WelcomeSection";


const Home = () => {
  return (
    <div>
      <WelcomeSection />


      {/* <Contact /> */}
    </div>
  );
};

export default Home;
